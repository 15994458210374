import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { RegisterPayload } from '../shared/models/payloads/RegisterPayload';
import { Observable, tap } from 'rxjs';
import { UpdateUserPayload } from '../shared/models/payloads/UpdateUserPayload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MODALS } from '../constants/Modals';
import { Urls } from '../constants/Urls';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private readonly iamUrl: string = '/v1/iam';
  private readonly accountsUrl: string = '/v1/iam/accounts';

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  public register(payload: RegisterPayload): Observable<any> {
    const formData = new FormData();
    formData.append('email', payload.email);
    formData.append('first_name', payload.first_name);
    formData.append('last_name', payload.last_name);
    formData.append('password', payload.password);
    formData.append('password_confirm', payload.password_confirm);
    formData.append('username', payload.username);
    formData.append('profile_picture', payload.profile_picture ?? '');
    formData.append('referral_link_code', payload.referral_link_code ?? '');

    return this.apiService.post(`${this.accountsUrl}/register/`, formData).pipe(
      tap(() => {
        const modalRef = this.modalService.open(MODALS['information'], { size: 'lg', backdrop: 'static' });
          modalRef.componentInstance.title = 'Congratulations!';
          modalRef.componentInstance.description1 = "Thank you for registering, you can now login to platform.";
          modalRef.componentInstance.proceedButtonLabel = 'Login';
          modalRef.componentInstance.cancelButtonLabel = 'Not now';
          modalRef.result.then((response) => {
            if (response.proceed) {
              this.router.navigate([`${Urls.APP}/${Urls.LOGIN}`]);
            } else {
              this.router.navigate([`/${Urls.APP}/${Urls.HOME}`]);
            }
          });
      })
    );
  }

  public userReferralLink(): Observable<any> {
    return this.apiService.get(`${this.iamUrl}/user-referral-link/`);
  }

  public updateProfile(payload: UpdateUserPayload, shouldHaveProfileInPayload: boolean = true): Observable<any> {
    const formData = new FormData();
    formData.append('first_name', payload.first_name);
    formData.append('last_name', payload.last_name);
    formData.append('username', payload.username);
    formData.append('theme', payload.theme ?? '');

    // condition to check if the request requires them to have a profile in payload, 
    // some of the requests doesn't require a profile in the payload (ex. theme saving), 
    // in this case nothing should change on the profile value
    if (shouldHaveProfileInPayload) {
      if (payload.profile_picture) {
        formData.append('profile_picture', payload.profile_picture, payload.profile_picture.name);
      } else {
        formData.append('profile_picture', '');
      }
    }

    return this.apiService.put(`${this.accountsUrl}/profile/`, formData);
  }
}
